#camp-entry {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    width: 100%;
}

.campaign-entry-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
#campaign-entry-container {
    flex: .7 0 200px;
}

#calendar-pickers {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}
.datepicker {
    max-width: 220px;
}

#mycamps-container {
    min-width: 20em;
}

.form-group {
    margin-top: 15px;
    margin-bottom: 15px;
}

#mycamps-container button {
    margin: 5px;
}

#camp-container {
    margin: 25px;
}

.text-group {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text-input-label-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5px;
    margin-bottom: 5px;
}

.text-label {
    margin-right: 15px;
}
#portals {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.portal-bag {
    background-color: #F0F0F0;
    border-style: solid;
    min-width: 360px;
    min-height: 210px;
    padding: 5px;
}
.portals-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // border: 1px solid #ddd;
    margin-left: -32px;
    // padding-left: 0px
    padding: 5px;
    margin-right: 5px;
}

ul {
    list-style-type: none;
}
.select-container {
    max-width: 200px;
}
#contact {
    display: flex;
}

#contact div {
    width: 200px;
    margin-right: 5px;
}
#camp-submit-btn {
    margin-bottom: 10px;
}

.logout-btn {
    margin: 5px;
}

#save-cred-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

button:hover {
    background-color: rgb(225, 225, 225);
}

// input:valid {background:yellow}
input:focus {background:rgb(135, 160, 175)}

.clickable {
    cursor: pointer;
    margin: 5px;
    padding: 3px;
    color: #121212;
}
.clickable:hover {
    background-color: #98B6B1
}
