.scrolling-logger {
    margin: 10px 0px 0px;
}
  
.scrolling-logger div {
    margin-bottom: 10px;
}
  
.scrolling-logger div input {
    margin: 10px;
}
  
.scrolling-logger pre {
    border: 1px;
    border-style: solid;
    background-color: #f1f1f1;
    overflow: scroll;
    max-height: 500px;
    padding-left: 5px;
}
  
.metrics {
    margin: 10px 0px 0px;
}

.scraper-meta-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.sub-heading {
    display: flex;
}

.datatable {
    height: 45em;
    width: '100%';
}
