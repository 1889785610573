#scrape-btn {
    background-image: linear-gradient(to right, #314755 0%, #849da8  51%, #314755 100%);
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #666;
    border-radius: 10px;
    display: block;
}
#scrape-btn:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
#scrape-btn:disabled, #scrape-btn[disabled] {
    background-image: linear-gradient(to right, #314755 0%, #a4a4a4  51%, #314755 100%);
    background: none;
    background-color: #A1A6B4;
    color: #000000;
    box-shadow: 0 0 5px #666;
    opacity: 0.25;
    cursor: not-allowed;
}
#scrape-btn:disabled, #scrape-btn[disabled] #scrape-btn:hover {
    background-position: left center;
}

#applyviqbackupcandidates {
    transition: 0.05s;
    background-color: #50514F;
    color: #c7c7c7;
}
#applyviqbackupcandidates:hover {
    background-color: #aaaea5;
    color: #4a4c48;
}
#job-editor-buttons {
    justify-content: space-between;
}
.btn-wrapper {
    transition: 0.05s;
}
.btn-wrapper:active {
    transform: translateY(2px);
}
.btn-internal {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;
}
.btn:active {
    transform: translateY(2px);
}

.submit-group button {
    margin: 10px 10px 10px 0px;
}

.submit-btn {
    margin: '10px 0px 0px 0px';
}

.add-btn {
    margin-top: 10px;
}

.btn-icon {
    margin: 0px 10px 0px 0px;
}

.btn {
    margin: 5px;
}

.mybtn {
    margin: 0px 5px;
}

.link-btn {
    color: black;
}

.btn-secondary {
    background-color: #424B54;
    color: white;
}

.flex-container {
    display: flex;
}

.bot-select-option-btn {
    background-color: #CCC5B9;
    padding: 5px 8px 8px;
    color: #585a5b;
}

.bot-select-option-btn:hover {
    color: white;
    background-color: #7a7978;
}

.overview-btn {
    display: flex;
    margin: 0px 5px 0px 0px;
    justify-content: center;
    align-items: center;
}

.btn.custom {
    margin: 3px 5px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    border-color: #ccc;
}

.btn.custom:hover {
    background-color: #7D8CA3;
}

.action-set>* {
    margin: 0 3px 0 3px;
}

.btn-general {
    background-color: #9ca7b3;
    padding: 6px 20px;
}
.btn-general:hover {
    background-color: #c8ccd1;
}
