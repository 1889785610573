#delta {
    display: flex;
    align-content: space-between;
    gap: 30px;
    width: 500px;
}

#two-dates {
    display: flex;
    justify-content: space-between;
}

#viq-job-edit {
    background-color: whitesmoke;
    margin: 10px 10px;
    padding: 2px;
}
