.cred-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
}

.input-label {
    display: grid;
    grid-template-columns: 1fr 2fr .25fr;
    margin: 3px;
}

label {
    height: 40px;
}

.input-field {
    width: 100%;
    margin: 0px 10px;
}

#cred-form {
    margin: 25px 0px;
}