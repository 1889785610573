
.nav-header {
    justify-content: space-around;
    flex-flow: row wrap;
}

.nav-icon {
    color: #495159;
}

.top-nav-btn {
    padding: 20px;
    text-decoration: none;
    color: black;
}
.top-nav-btn:hover {
    background-color: lightgrey;
}
.top-nav-btn > .btn-internal {
    justify-content: space-between;
}

// scrape logo style
.home-btn-container button::before {
    content: "";
    background-image: url("../images/data-logo.png");
    margin-right: 1.5rem;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
}

.btn-link {
    text-decoration: none;
}

#sidebar button {
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    border-top: 1px solid #e3e3e3;
    line-height: 1;
}

#bot-select-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}
  
#configSelect {
    width: 15em;
}

#scrapeselect {
    width: 15em;
}

// sidebar
#sidebar {
    width: 22rem;
    background-color: #f7f7f7;
    border-right: solid 1px #e3e3e3;
    display: flex;
    flex-direction: column;
}
  
#sidebar > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

#sidebar nav {
    flex: 1;
    overflow: auto;
    padding-top: 1rem;
  }
  
#sidebar nav a span {
    float: right;
    color: #eeb004;
}
#sidebar nav a.active span {
    color: inherit;
}
  
i {
    color: #818181;
}
#sidebar nav .active i {
    color: inherit;
}

#sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#sidebar li {
    margin: 0.25rem 0;
}

#sidebar nav a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    white-space: pre;
    padding: 0.5rem;
    border-radius: 8px;
    color: inherit;
    text-decoration: none;
    gap: 1rem;
}

#sidebar nav a:hover {
    background: #e3e3e3;
}

#sidebar nav a.active {
    background: hsl(224, 98%, 58%);
    color: white;
    }

#sidebar nav a.pending {
    color: hsl(224, 98%, 58%);
}