.warn {
    color: red;
    font-weight: bold;
}

.overview-bad {
    color: #7B2D26;
    border-radius: 5px;
    border-color: black;
}

.overview-good {
    color: #19535F;
    border-color: black;
}

.danger {
    color: #d32f2f;
}
